.help {
  position: relative;
}

.help-dropdown {
  background-color: var(--st-content-bg);
  border: 1px solid var(--st-border-color);
  display: none;
  padding: var(--st-spacer-md);
  position: absolute;
  right: -10px;
  top: 18px;
  width: 260px;
  z-index: 1;
}

.help-dropdown-left-anchored {
  left: -10px;
  right: auto;
}

:global(.st-mode--light) .help-dropdown {
  filter: drop-shadow(0 4px 4px rgb(0 0 0 / 25%));
}

:global(.st-mode--dark) .help-dropdown {
  filter: drop-shadow(0 4px 4px rgb(255 255 255 / 25%));
}

.help-icon {
  color: var(--st-input-icon-color);
  cursor: pointer;
}

.help:hover .help-dropdown {
  display: block;
}
