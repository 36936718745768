/* Note: There are better ways to dynamically make the sections scrollable like redoing the flex structure for
     the hierarchy, but this was the quickest and least invasive way. We will need to adjust these hardcoded
     values if any part of the page nav/headers changes height.
  */

/*                                   header subnav column-header */
$off-platform-edit-header-height: calc(60px + 41px + 72px);
$off-platform-edit-margin-borders: 36px;
$off-platform-edit-shared-heights: calc($off-platform-edit-header-height + $off-platform-edit-margin-borders);

.curate-scrollable {
  box-sizing: border-box;
  //                                 tabs padding title search filters margin align
  max-height: calc(100vh - $off-platform-edit-shared-heights - 25px - 8px - 21px - 58px - 48px - 8px - 64px);
  overflow-y: auto;
}

.history-scrollable {
  box-sizing: border-box;

  /*                    history:    tabs top-padding header nav-buttons top-info separators bottom-info borders */
  max-height: calc(100vh - $off-platform-edit-shared-heights - 41px - 8px - 37px - 32px - 93px - 2px - 48px - 2px);
  overflow-y: scroll;
}

.settings-info-labels {
  cursor: initial;
}

.second-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.second-header > div {
  display: flex;
}
