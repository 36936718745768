.droppable {
  align-items: center;
  background-color: var(--st-btn-gray-bg-active);
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  min-height: 10rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: var(--st-zindex-mad);
}

.droppable-inactive {
  opacity: 0;
  pointer-events: none;
  z-index: -1;
}

.anchor {
  position: relative;
}

.placeholder {
  height: 0;
  overflow: hidden;
  width: 0;
}
