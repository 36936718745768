$dialog-padding: var(--st-spacer-mlg);

.divider {
  border-style: solid none none;
  border-top: 1px solid var(--st-border-color);
  height: 1px;
  margin-bottom: var(--st-spacer-md);
  margin-left: calc($dialog-padding * -1);
  margin-top: var(--st-spacer-md);
  width: calc(100% + $dialog-padding * 2);
}

.buttons {
  display: flex;
  justify-content: flex-end;
}
