.search-box {
  align-items: center;
  display: flex;
}

.search-input {
  position: relative;
  width: 100%;
}

.search-help-icon {
  position: absolute !important;
  right: var(--st-spacer-md);
  top: 50%;
  transform: translateY(-50%);
}

.search-content-wrapper {
  position: relative;
}
