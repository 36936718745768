.publish-state {
  align-items: flex-end;
  display: flex;
}

.actions {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--st-spacer-md);
}

.edit-mode-container {
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.history-scrollable {
  overflow: hidden !important;
  position: relative;

  .edit-notice {
    background-color: var(--st-content-bg);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }
}

.last-edited-by-text {
  display: flex;
  gap: 10px;
}
