/* Note: There are better ways to dynamically make the sections scrollable like redoing the flex structure for
     the hierarchy, but this was the quickest and least invasive way. We will need to adjust these hardcoded
     values if any part of the page nav/headers changes height.
  */

/*                                   header subnav column-header */
$off-platform-edit-header-height: calc(60px + 41px + 72px);
$off-platform-edit-margin-borders: 36px;
$off-platform-edit-shared-heights: calc($off-platform-edit-header-height + $off-platform-edit-margin-borders);

.curate-scrollable {
  box-sizing: border-box;
  //                                 top-margin tabs padding title search filters margin align
  max-height: calc(100vh - $off-platform-edit-shared-heights - 24px - 25px - 8px - 21px - 58px - 48px - 8px - 64px);
  overflow-y: auto;
}

.history-scrollable {
  box-sizing: border-box;

  /*              history:    top-margin tabs top-padding header nav-buttons top-info separators bottom-info borders */
  max-height: calc(
    100vh - $off-platform-edit-shared-heights - 24px - 41px - 8px - 37px - 32px - 93px - 2px - 48px - 2px
  );
  overflow-y: auto;
  padding: 16px;
}

.settings-info-labels {
  cursor: initial;
}

.top-page-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.top-page-header > div {
  display: flex;
}

.module-header {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

.module-background {
  background-color: var(--st-border-color);
  border-radius: 8px;
  padding: 16px;
}

.horizontal-divider {
  border-style: solid none none;
  border-top: 1px solid var(--st-border-color);
  height: 0;
  left: 0;
  position: absolute;
  width: 100vw;
}

.vertical-divider {
  border-left: 1px solid var(--st-border-color);
  min-width: 16px;
  transform: translate(8px, -8px);
}
