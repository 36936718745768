.page-wrapper {
  position: relative;
}

.page-background {
  background-color: var(--st-btn-gray-bg-active);
  border-radius: 8px;
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
