.header-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--st-spacer-sm);
}

.input-form {
  width: 100%;
}
