.wrapper {
  color: #636e72;
}

.dashed-box {
  align-items: flex-start;
  border: 1px dashed #b2bec3;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: var(--st-spacer-md);
}
