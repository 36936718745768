.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--st-spacer-lg);
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.sort-list {
  display: flex;
  flex-direction: column;
  gap: var(--st-spacer-md);
  width: 100%;
}
