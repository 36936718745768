.module-wrapper {
  width: 100%;
}

.module-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60px;
  
  &[data-error='true'] {
    background-color: var(--st-input-bg-error);
    border: 1.5px dashed var(--st-input-color-error);
  }
}

.module-dashed-area {
  border: 1px dashed var(--st-text-color-support);
}

.module-feeds-area {
  background: var(--st-color-stainless);
  border: 1px solid var(--st-color-gray);
  border-radius: 8px;
}

.module-droppable-area {
  display: flex;
  flex-direction: column;
  gap: var(--st-spacer-xs);
  width: 100%;
}

.draggable-module {
  align-items: center;
  display: flex;
  width: 100%;
}

.module-icon-container {
  display: flex;
  flex-direction: column;
  gap: var(--st-spacer-xs);
}
