.search-refresh {
  align-items: center;
  display: flex;
  gap: var(--st-spacer-xs);
  height: var(--st-spacer-mlg);
}

.search-refresh-button {
  font-size: 13px;
  font-weight: 400;
}
