@import './SectionPageEdit.variables';

.page-edit {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.page-edit-row {
  display: flex;
  gap: var(--st-spacer-md);
  justify-content: center;
}

.page-edit-draft {
  display: flex;
  flex: 1.5;
  flex-direction: column;

  /*                draft:      bottom-margin */
  max-height: calc(100vh - $page-edit-shared-heights);
  max-width: 893px;
}

.page-divider {
  border-left: 1px solid var(--st-border-color);
  flex: 0;
  min-width: 16px;
  transform: translate(8px, -8px);
}

.horizontal-divider {
  border-style: solid none none;
  border-top: 1px solid var(--st-border-color);
  height: 0;
  left: 0;
  position: absolute;
  width: 100vw;
}

.page-edit-search {
  flex: 1;
  max-width: 590px;
}

.page-edit-illustration {
  max-width: 400px;
}

.page-edit-body {
  max-width: 400px;
}

.header-content {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.history-scrollable {
  box-sizing: border-box;

  /* history:    top-pad tabs top-pad header nav-buttons top-infoseparators bottom-info borders */
  max-height: calc(100vh - $page-edit-shared-heights - 24px - 41px - 8px - 37px - 32px - 93px - 2px - 48px - 2px);
  overflow-y: scroll;
}

.search-result-scrollable {
  box-sizing: border-box;

  /* search:     top-pad tabs top-pad header inner-tabs top-pad search-box filter-bar */
  max-height: calc(100vh - $page-edit-shared-heights - 48px - 41px - 8px - 37px - 25px - 8px - 65px - 48px);
  overflow-y: scroll;
}

.search-result-scrollable-embargo {
  box-sizing: border-box;

  /* search:     top-pad tabs top-padding header */
  max-height: calc(100vh - $page-edit-shared-heights - 24px - 41px - 8px - 21px);
  overflow-y: scroll;
}

.search-result-scrollable-without-collections {
  box-sizing: border-box;

  /* search:     top-pad tabs top-padding margin-top search filters inner-tabs */
  max-height: calc(100vh - $page-edit-shared-heights - 24px - 25px - 7px - 7px - 58px - 28px);
  overflow-y: scroll;
}
