.card-container {
  background: var(--st-color-stainless);
  width: 100%;
}

.card-header {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.headline {
  line-height: 16px;
}
