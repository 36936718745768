.module-list {
  position: relative;
}

.container {
  padding: var(--st-spacer-md) 0;
}

.module-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.module-container .text {
  margin-bottom: 0;
}
