.dialog {
  width: 475px;
}

.token {
  align-items: center;
  border-radius: 15%;
  display: flex;
  justify-content: center;
}

.embargoed-articles .embargoed-article {
  margin-bottom: var(--st-spacer-md);
}

.embargoed-articles .embargoed-article:last-child {
  margin-bottom: 0;
}
