.wrapper {
  align-items: center;
  display: flex;
  gap: var(--st-spacer-sm);
  width: 100%;
}

.button-group {
  display: flex;
  gap: 4px;
}

.select {
  flex: 1;
  min-width: 205px;
}
