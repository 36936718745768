@use 'theme/abstracts/mixins';

.image,
.image-enlarged {
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 40px;
  width: 40px;
}

.image-enlarged {
  display: block;
  filter: drop-shadow(var(--st-color-asphalt) 0 0 var(--st-spacer-sm));
  position: absolute;
  top: -100%;
  width: 180px;
  z-index: 1;
}

.image-container {
  position: relative;
}

.no-image {
  background-color: #fafafa;
}

.headline {
  @include mixins.ellipsis(2);

  display: inline;
  flex: 1;
  line-height: 20px;
}

.icon {
  color: var(--st-color-gray);
  vertical-align: middle;
}

.no-border {
  border: none;
}

.overflow {
  background-color: transparent;
}

.itp-new-content {
  background-color: var(--st-content-bg-hover);
}

.itp-stale-content {
  background-color: var(--st-input-bg-error);
}

.left {
  left: calc(var(--st-spacer-sm) * -3);
}

.error-article {
  background-color: var(--st-input-track-color-error);
}

.no-icon {
  background-color: var(--st-color-stainless);
  color: var(--st-color-gray);
  height: 40px;
  width: 40px;

  .image {
    height: 18px;
    padding: 11px;
    width: 18px;
  }
}

.dot {
  line-height: 20px;
}
