/* Note: There are better ways to dynamically make the sections scrollable like redoing the flex structure for
       the hierarchy, but this was the quickest and least invasive way. We will need to adjust these hardcoded
       values if any part of the page nav/headers changes height.
/*   header subnav column-header  */

$collection-edit-header-height: calc(60px + 41px + 72px);
$collection-edit-margin-borders: 36px;
$collection-edit-shared-heights: calc($collection-edit-header-height + $collection-edit-margin-borders);

$collection-edit-section-header: 75px;

.items-scrollable {
  box-sizing: border-box;
  height: 100%;
  max-height: calc(100vh - $collection-edit-shared-heights - $collection-edit-section-header);
  padding: 16px;
}

.draft-items {
  background-color: var(--st-border-color);
}

.wrapper-overflow {
  overflow-y: auto;
}

.draft-items-list {
  border: 1px dashed var(--st-text-color-support);
}

.items-list {
  display: flex;
  flex-direction: column;
  gap: var(--st-spacer-sm);
  padding: 4px;
}

.items-number {
  align-items: center;
  display: flex;
  justify-content: center;
}

.items-number-with-content {
  padding: 4px 0 8px;
}
