.st_alert {
  div {
    display: flex;
    flex-direction: column;
  }

  .flex-align-row {
    align-items: center;
    display: flex;
    flex-direction: row;

    svg {
      margin-right: var(--st-spacer-sm);
    }
  }

  .st_btn {
    padding-left: 0;
  }
}

.not-found-wrapper {
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
}

.items-list {
  display: flex;
  flex-direction: column;
  gap: var(--st-spacer-sm);
}
