@use 'theme/abstracts/mixins';

.card {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.card.highlight {
  background-color: var(--st-content-bg-hover);
}

.card.highlight-extra {
  background-color: transparent;
  border-color: var(--st-input-track-color);
}

.card.stale {
  background-color: var(--st-input-bg-error);
}

.card-left {
  align-items: center;
  display: flex;
}

.image-container {
  position: relative;
}

.image,
.image-enlarged {
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 40px;
  width: 40px;
}

.image-enlarged {
  display: block;
  filter: drop-shadow(var(--st-color-asphalt) 0 0 var(--st-spacer-sm));
  position: absolute;
  top: -100%;
  width: 180px;
  z-index: 1;
}

.left {
  left: calc(var(--st-spacer-sm) * -3);
}

.no-image {
  background-color: #fafafa;
}

.headline {
  @include mixins.ellipsis(2);

  display: inline;
  flex: 1;
  line-height: 20px;
}

.icon {
  color: var(--st-color-gray);
  vertical-align: middle;
}
