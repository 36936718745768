.modal-box {
  width: 600px;
}

.groups-wrapper {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 400px 156px;

  .groups-item {
    align-self: flex-start;
    display: flex;
  }
}

.dynamic-content-box {
  height: 425px;
  overflow-y: auto;
}

.inputs-wrapper {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 32px;

  textarea {
    background: transparent;
    border: none;
    color: var(--st-text-color);
    letter-spacing: 0;
    outline: none;
    overflow: hidden;
    overflow-wrap: break-word;
    padding: 0;
    resize: none;

    &:focus-visible {
      border-color: var(--st-input-color-active);
      box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50);
    }

    &.headline-textarea {
      font-family: Georgia, serif;
      font-size: 1.25rem;
      font-weight: 700;
      grid-row: 1;
      line-height: 1.2;
      margin-bottom: 4px;
    }

    &.body-textarea {
      font-family: Arial, sans-serif;
      font-size: 0.9rem;
      font-weight: 350;
      grid-row: 2;
      line-height: 1.4;
    }
  }

  .headline-char-count {
    color: var(--st-color-asphalt);
    justify-self: end;
  }
}

.footer-buttons-wrapper {
  width: 100%;
}
