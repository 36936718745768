.header-content {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.second-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.second-header > div {
  display: flex;
}

/* Note: There are better ways to dynamically make the sections scrollable like redoing the flex structure for
   the hierarchy, but this was the quickest and least invasive way. We will need to adjust these hardcoded
   values if any part of the page nav/headers changes height.
*/

/*                                   header subnav column-header */
$collection-edit-header-height: calc(60px + 41px + 72px);
$collection-edit-margin-borders: 36px;
$collection-edit-shared-heights: calc($collection-edit-header-height + $collection-edit-margin-borders);

$collection-edit-section-header: 37px;

.info-scrollable {
  box-sizing: border-box;
  max-height: calc(100vh - $collection-edit-shared-heights);
  overflow-y: scroll;
}

.items-scrollable {
  box-sizing: border-box;
  max-height: calc(100vh - $collection-edit-shared-heights - $collection-edit-section-header);
  overflow-y: scroll;
}

.curate-scrollable {
  box-sizing: border-box;
  //                                                         tabs padding search filters margin
  max-height: calc(100vh - $collection-edit-shared-heights - 25px - 8px - 58px - 48px - 8px);
  overflow-y: scroll;
}

.second-header .save-button {
  display: inline-block;
  text-align: right;
}
