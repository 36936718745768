.countdown {
  align-items: center;
  color: var(--st-color-sunshine-70);
  display: flex;
}

.icon {
  margin-right: var(--st-spacer-sm);
  min-height: var(--st-spacer-md);
  min-width: var(--st-spacer-md);
}
