.text-form {
  position: relative;
}

.text-form[data-container='custom'] {
  width: 221px;
}

.autocomplete-box {
  border: none;
  border-radius: 0;
  box-shadow: 0 4px 16px 0 rgb(0 0 0 / 10%);
  max-height: 200px;
  min-width: 200px;
  overflow-y: scroll;
  position: absolute;
  right: 0;
  top: calc(100% + 1px);
  width: 100%;
  z-index: 1;
}

.option {
  border: none;
  border-radius: 0;
  cursor: pointer;
  text-transform: capitalize;
}

.option:hover {
  background-color: var(--st-color-blurple-20);
}
