@use 'theme/abstracts/mixins';

.truncated-text {
  @include mixins.ellipsis(1);

  max-width: calc(100% - var(--st-spacer-sm));
}

.header {
  align-items: center;
  display: flex;
}
