.items-list {
  display: flex;
  flex-direction: column;
  gap: var(--st-spacer-sm);
}

.items-list-empty {
  border: 1px dashed var(--st-text-color-support);
}

.empty-curated-list {
  align-items: center;
  display: flex;
  justify-content: center;
}

.empty-curated-list-dragging {
  opacity: 0.2;
}
