.duplicate-item-card {
  &--light {
    background-color: var(--st-color-sunshine-05);
  }

  &--dark {
    background-color: var(--st-color-sunshine-70);
  }
}

.duplicate-item-query,
.duplicate-item-query p {
  color: var(--st-color-sunshine-60);
}
