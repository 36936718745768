@import './ItpEdit.variables';

.page-edit-row {
  display: flex;
  gap: var(--st-spacer-md);
  justify-content: center;
}

.page-edit-draft {
  display: flex;
  flex: 1.5;
  flex-direction: column;

  /*                draft:      bottom-margin */
  max-height: calc(100vh - $itp-edit-shared-heights);
  max-width: 893px;
}

.page-divider {
  border-left: 1px solid var(--st-border-color);
  flex: 0;
  min-width: 16px;
  transform: translate(8px, -8px);
}

.horizontal-divider {
  border-style: solid none none;
  border-top: 1px solid var(--st-border-color);
  height: 0;
  left: 0;
  position: absolute;
  width: 100vw;
}

.page-edit-search {
  flex: 1;
  max-width: 590px;
}

.draft-container {
  overflow-y: auto;
}
