.subtitle-url-form {
  display: flex;
}

.base-route-box {
  align-items: center;
  background-color: var(--st-color-stainless);
  box-sizing: border-box;
  display: flex;
  height: 40px;
}
