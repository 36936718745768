.bullet-item-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.bullet-item {
  background: transparent;
  border: none;
  color: var(--st-text-color);
  font-family: Arial, sans-serif;
  font-size: 0.9rem;
  font-weight: 350;
  grid-row: 3;
  letter-spacing: 0;
  line-height: 1.4;
  outline: none;
  overflow: hidden;
  overflow-wrap: break-word;
  padding: 0;
  resize: none;

  &:focus-visible {
    border-color: var(--st-input-color-active);
    box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50);
  }

  width: 100%;
}

.bullet-group {
  width: 100%;
}

.bullet-item-drag-handle {
  cursor: grab;
}
