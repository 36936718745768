.overlay {
  width: 80vw;
}

.content {
  display: flex;
  max-height: calc(100vh - 160px);
  overflow-y: scroll;
}

.content-left {
  flex: 1;
}

.content-right {
  flex: 1;
}

.content-list {
  display: flex;
  flex-direction: column;
  gap: var(--st-spacer-xs);
}

.bottom-bar {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
