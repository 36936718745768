.collection-items-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.collection-items-header-left {
  align-items: center;
  display: flex;
}

.collection-items-wrapper {
  box-sizing: border-box;
  height: 100%;
  position: relative;
}

.collection-items-wrapper-background {
  background-color: var(--st-btn-gray-bg-active);
  height: 100%;
  left: 0;
  opacity: 0.2;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
