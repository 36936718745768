.collection-item {
  box-shadow: 0 4px 8px 0 transparent;
  min-height: 66px;
  position: relative;
  transition: box-shadow 0.4s;

  &:hover {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%);
  }
}

.collection-item-clickable {
  cursor: pointer;
}
