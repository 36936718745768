@use 'theme/abstracts/mixins';

.wrapper {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;

  &__inner {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &:hover {
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 10%);
  }
}

.wrapper-error {
  align-items: flex-start;
  background-color: var(--st-input-track-color-error);
  display: flex;
  justify-content: space-between;

  &__inner {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &:hover {
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 10%);
  }
}

.wrapper-dragging {
  box-shadow: 0 4px 16px 0 rgb(0 0 0 / 10%);
}

.left {
  display: flex;
  flex-direction: column;
  gap: var(--st-spacer-sm);
}

.header {
  @include mixins.ellipsis;
}

.description {
  @include mixins.ellipsis(2);
}

.image {
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 98px;
  min-width: 98px;
  width: 98px;
}

.no-image {
  background-color: #fafafa;
}

.url {
  @include mixins.ellipsis;
}

.headline {
  display: flex;
}

.error-article {
  background-color: var(--st-input-track-color-error);
}

.no-border {
  border: none;
}

.image-container {
  position: relative;
}
