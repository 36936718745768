.dropdown {
  min-width: 190px;
}

.wrapper {
  display: flex;
}

.group {
  flex-wrap: nowrap;
}
