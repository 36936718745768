.page-root-container {
  display: flex;
  flex-direction: column;
}

.page-root-container-content {
  flex: 1;
}

.actions-bar {
  border-bottom: 1px solid var(--st-border-color);
}
