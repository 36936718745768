.card-container {
  width: 100%;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.headline {
  line-height: 16px;
}
