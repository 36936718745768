.off-platform-wrapper {
  position: relative;
}

.list {
  display: flex;
  flex-direction: column;
  gap: var(--st-spacer-sm);
  padding-top: var(--st-spacer-sm);
}

.item {
  box-shadow: 0 4px 8px 0 transparent;
  cursor: pointer;
  min-height: 66px;
  position: relative;
  transition: box-shadow 0.4s;

  &:hover {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%);
  }
}
