.page-wrapper {
  position: relative;
}

.page-background {
  &.round-border-start {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &.round-border-end {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  background-color: var(--st-btn-gray-bg-active);
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
