.page-item {
  box-shadow: 0 4px 8px 0 transparent;
  cursor: pointer;
  transition: box-shadow 0.4s;

  &:hover {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%);
  }
}

.page-item-content {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.page-item-left {
  align-items: center;
  display: flex;
}

.empty-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 300px;
}

.empty-image {
  max-width: 300px;
}
