.addable-ui-module-option {
  cursor: pointer;
  transition: background-color 500ms !important;

  &:hover {
    background-color: var(--st-color-blurple-20);
  }
}

.block-divider {
  margin-top: var(--st-spacer-xs);

  &:hover .block-divider-content {
    visibility: visible;
  }
}

.block-divider-content {
  align-items: center;
  display: flex;
  text-align: center;
  visibility: hidden;
}

.block-divider-divider {
  border-style: solid none none;
  border-top: 1px solid var(--st-border-color);
  flex-grow: 1;
  height: 0;
}

.block-divider-dropdown-content {
  transform: translateX(calc(-50% + 12px));
}
