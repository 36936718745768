.navigator {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--st-spacer-sm);
}

.illustration {
  margin: 0 auto;
  width: 70%;
}
