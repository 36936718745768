.label {
  align-items: center;
  display: inline-flex;
}

.active {
  background: var(--st-btn-secondary-bg-active);
  border-radius: var(--st-border-radius);
  color: var(--st-btn-secondary-color-hover);
  height: var(--st-spacer-mlg);
}

.drop-down {
  width: max-content;
}
