.image-preview-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

.image-preview {
  object-fit: cover;
  width: 100%;
}

.image-skeleton-loader {
  background-color: var(--st-border-color);
  position: absolute;
}

.preview-icons {
  border-radius: var(--st-border-radius);
  bottom: 10px;
  left: 7px;
  position: absolute;

  .preview-icon {
    background-color: white;
  }
}

.add-image-button {
  width: 25%;
}
