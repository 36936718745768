.editor-item {
  align-items: center;
  display: flex;
}

.page-button {
  height: fit-content !important;
  text-align: start;
  white-space: normal;
}
