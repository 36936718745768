.empty-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 250px;
  text-align: center;
}

.empty-image {
  max-width: 250px;
}
