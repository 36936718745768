.issue-box {
  box-shadow: 0 4px 8px 0 transparent;
  cursor: pointer;
  display: block;
  text-decoration: none;
  transition: box-shadow 0.4s;

  &:hover {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%);
  }
}

.token-wrapper {
  align-items: center;
  display: flex;
  min-width: 64px;
  text-align: right;
}

.alert-icon {
  // The icon has a margin-top of 3px
  margin-top: -3px;
}

@keyframes fade-out-blurple {
  0% {
    background-color: var(--st-color-blurple-10);
  }

  100% {
    background-color: none;
  }
}

.itp-new-issue {
  animation: 4s linear 0s 1 fade-out-blurple;
}
