.divider-group {
  display: flex;
  flex-direction: column;
  gap: var(--st-spacer-sm);
  padding: var(--st-spacer-md);
}

.normal-group {
  display: flex;
  flex-direction: column;
  padding: var(--st-spacer-md);
}
