.date-buttons {
  display: flex;
}

.date-filter-content {
  min-width: 558px; // to fit two months side-by-side
}

.date-filter-actions {
  display: flex;
  justify-content: flex-end;
}

.active {
  background: var(--st-btn-secondary-bg-active);
  border-radius: var(--st-border-radius);
  color: var(--st-btn-secondary-color-hover);
  height: var(--st-spacer-mlg);
}
