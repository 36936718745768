.date-filter-actions {
  display: flex;
  justify-content: center;
}

.date-filter-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  max-height: 350px;
}

.published-pages {
  height: 300px;
  overflow-y: scroll;
  width: 270px;
}
