* {
  border: 0;
  margin: 0;
  outline: none !important;
  padding: 0;
  -webkit-tap-highlight-color: rgb(255 255 255 / 0%);
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  background-color: var(--st-page-bg);
  line-height: 1;
  width: 100vw;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button::-moz-focus-inner {
  border: 0;
}
