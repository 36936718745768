.button-group {
  display: flex;
  flex-wrap: nowrap;
}

.active {
  background: var(--st-btn-secondary-bg-active);
  border-radius: var(--st-border-radius);
  color: var(--st-btn-secondary-color-hover);
  height: var(--st-spacer-mlg);
}
