.top-header-wrapper {
  display: flex;
  justify-content: space-between;
}

.module-list {
  position: relative;
}

.module-list-background {
  background-color: var(--st-btn-gray-bg-active);
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.no-color {
  background-color: none !important;
}

.droppable-container {
  padding: var(--st-spacer-sm) var(--st-spacer-md) var(--st-spacer-md) var(--st-spacer-md);
}

.dedupe-button {
  margin-right: 10px;
}

.wrapper-overflow {
  overflow-y: auto;
}

.draft-container {
  overflow-y: auto;
}
