.wrapper {
  display: flex;
  gap: var(--st-spacer-sm);
  justify-content: center;
  min-width: 100%;
}

.select {
  flex: 0 0 auto;
  width: auto;
}

.button-group {
  display: flex;
  gap: 4px;
  height: 40px;
}

.input-component {
  flex: 1;
}
