.title-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.row {
  align-items: center;
  display: flex;
  gap: var(--st-spacer-mlg);
}

.publishing-buttons {
  align-items: center;
  display: flex;
}

.embargo-warning {
  align-items: center;
  display: flex;
  gap: 8px;

  > * {
    color: var(--st-color-sunshine-70);
  }
}
