.title-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.row {
  align-items: center;
  display: flex;
  gap: var(--st-spacer-mlg);
}
