.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.form-label {
  width: 100%;
}

.display-name {
  line-height: 30px;
}
